import axios from './axios';
import { UserResponse, UserRole } from './users';

interface LoginResponse {
    jwt: string;
}
export const login = (username: string, password: string) => {
    return axios.post<LoginResponse>('/auth/login', {
        username,
        password
    }, {
        validateStatus: function (status) {
            return status == 201 || status == 401;
          }
    });
}

export const signup = (email: string, username: string, password: string, language: string = "en") => {
    return axios.post('/auth/register', {
        email,
        username,
        password,
        language
    });
}


export interface AssociateResponseDto {
    username: string;
    role: UserRole;
    email: string;
    id: string;
  }
export const associateAuth0 = () => {
    return axios.post<AssociateResponseDto>('/auth/associate');
}