import React, { InputHTMLAttributes } from "react";
import {
  FieldValues,
  UseFormRegister,
  useFormContext,
} from "react-hook-form";
import styles from "./Input.module.css";
import cn from 'clsx';
import { Label } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

export interface InputProps {
  name: string;
  label: string;
  className?: string;
  [rest: string]: any;
  renderContainer?: (props: any) => JSX.Element;
}
export const Input = ({ name, label, className, renderContainer = (props) => <input {...props}></input>, ...rest }: InputProps) => {
  const methods = useFormContext<FieldValues>();
  const { register, formState: { errors } } = methods;
  const errorMessage = errors[name]?.message as string;

  const cls = cn(styles.input, {
    [styles.has_error]: !!errorMessage
  }, className)

  return (
    <>
        <Label label={label} />
        {renderContainer({
          className: cls,
          ...register(name),
          ...rest
        })}
        {errorMessage && <ErrorMessage message={errorMessage} />  }
    </>
  );
}

