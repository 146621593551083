import useTranslation from 'next-translate/useTranslation';
import LoginIcon from '../../../public/images/icons/login.svg';
import { SubmitButton } from "../../Form/SubmitButton/SubmitButton";

export const LoginFormButton = () => {
    const { t, lang } = useTranslation('common');
    
    return (
        <SubmitButton icon={<LoginIcon className="stroke-white stroke-[3px] w-8 h-8" />} label={t('auth.login')} />
    )
}