import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router"
import { TextButton } from "../../Buttons/TextButton"
import { FormTab } from "../../Form/FormTabs/FormTab";
import { FormTabs } from "../../Form/FormTabs/FormTabs"

export const AuthTabs = () => {
    const router = useRouter();
    const { t } = useTranslation('common')

    return (
        <FormTabs>
            <FormTab label={t('auth.login')} href="/login" side="left" />
            <FormTab label={t('auth.signUp')} href="/signup" side="right" />
        </FormTabs>
    )
}