import { useFormContext } from "react-hook-form";
import { LoadingIconContainer } from "../../Buttons/LoadingIconContainer";
import { UnderlinedButton } from "../../Buttons/UnderlinedButton";
import LoginIcon from '../../public/images/icons/login.svg';

interface SubmitButtonProps {
    label: string;
    icon: any;
}
export const SubmitButton = ({ label, icon }: SubmitButtonProps) => {
    const {
        formState: { isSubmitting }
    } = useFormContext();

    return (
        <UnderlinedButton className="mx-auto" type="submit" disabled={isSubmitting}>
            <LoadingIconContainer className="gap-x-2" loading={isSubmitting}
                responsiveText={false} icon={icon} label={label} />
        </UnderlinedButton>
    )
}