import styles from './TextButton.module.css';
import cn from 'clsx';
import { ReactElement } from 'react';

export interface TextButtonProps {
    side: 'left' | 'middle' | 'right';
    active?: boolean;
    children?: ReactElement | ReactElement[] | string;
    renderContainer?: (props: any) => JSX.Element;
    [rest: string]: any;
    className?: string;
}
export const TextButton = ({ 
    side, 
    active = false, 
    children, 
    className,
    renderContainer = (props) => <button {...props} />,
    ...rest }: TextButtonProps) => {
    const cls = cn(styles.text_button, styles.primary, {
        [styles.left_rounded]: side === 'left',
        [styles.right_rounded]: side === 'right',
        [styles.active]: active
    }, className);

    return (
        renderContainer({
            className: cls,
            children,
            ...rest
        })
    )
}