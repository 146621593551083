import { ReactElement } from 'react';
import styles from './FormControl.module.css';

interface FormControlProps {
    children: any;
}
export const FormControl = ({ children }: FormControlProps) => {
    return (
        <div className={styles.form_control}>
            {children}
        </div>
    );
}