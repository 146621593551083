import React, { InputHTMLAttributes } from "react";
import styles from "./Input.module.css";
import cn from 'clsx';

export const Label = ({ className, label }: any) => {
    const cls = cn(styles.label, className);
    return (
        <>
            <label className={cls}>{label}</label>
        </>
    );
}

