import { ReactElement } from "react"
import { TextButton } from "../../Buttons/TextButton"

export interface FormTabsProps {
    children: ReactElement | ReactElement[];
}
export const FormTabs = ({ children }: FormTabsProps) => {
    return (
        <div className="flex justify-center">
            {children}
        </div>
    )
}