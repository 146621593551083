import Link from "next/link";
import { useRouter } from "next/router";
import { TextButton } from "../../Buttons/TextButton";

export interface FormTabProps {
    label: string;
    href: string;
    side: 'left' | 'middle' | 'right';
}
export const FormTab = ({ label, href, side }: FormTabProps) => {
    const router = useRouter();
    
    return (
        <TextButton renderContainer={(props) => <Link {...props} />} href={href} active={router.pathname === href} side={side}>{label}</TextButton>
    )
}