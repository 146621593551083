import { FormContainer } from '../../Form/FormContainer/FormContainer';
import { FormControl } from '../../Form/FormControl/FormControl';
import styles from './Login.module.css';
import { useForm } from 'react-hook-form';
import { Input } from '../../Form/Inputs/Input';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useCallback, useContext } from 'react';
import { login } from '../../../../../packages/tutu-porn-api-client/src/auth';
import { useRouter } from 'next/router';
import { LoginFormButton } from './LoginFormButton';
import { FormTabs } from '../../Form/FormTabs/FormTabs';
import { TextButton } from '../../Buttons/TextButton';
import Link from 'next/link';
import { AuthTabs } from '../AuthTabs/AuthTabs';
import useTranslation from 'next-translate/useTranslation'

type LoginFormData = {
    username: string;
    password: string;
};

const LoginFormSchema = Joi.object({
    username: Joi.string().required(),
    password: Joi.string().required(),
});

export const Login = () => {
    const form = useForm<LoginFormData>({
        resolver: joiResolver(LoginFormSchema)
    });
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = form;
    const router = useRouter();
    const { t, lang } = useTranslation('auth')

    const onSubmit = useCallback(async (data: LoginFormData) => {
        const resp = await login(data.username, data.password);

        if (resp.status === 201) {
            const { jwt } = resp.data;
            localStorage.setItem('token', jwt);
            window.dispatchEvent( new Event('storage') );
            await router.replace(router.query.from as string || '/');
        }
        else if (resp.status === 401) {
            setError('password', {
                message: t('login.error.password')!
            });
        }
    }, []);

    const onLogin = useCallback(() => {

    }, []);

    const onSignUp = useCallback(() => {

    }, []);

    return (
        <FormContainer form={form} footer={() => <LoginFormButton />} onSubmit={handleSubmit(onSubmit)} className={styles.login}>
            <>
                <AuthTabs />

                <h3>{t('login.title')}</h3>
                <h6>{t('subtitle')}</h6>
                <FormControl>
                    <Input name="username" label={t('login.username.title')} placeholder={t('login.username.placeholder')} type="text" autoComplete="off" autoCorrect="off" autoCapitalize="none" />
                </FormControl>

                <FormControl>
                    <Input name="password" label={t('login.password.title')} placeholder={t('login.password.placeholder')} type="password" autoComplete="current-password" />
                </FormControl>

                <Link href="/forgot-password" className={styles.forgot_password}>
                    {t('login.forgot')}
                </Link>
            </>
        </FormContainer>
    );
}