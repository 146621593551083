import { ReactElement } from 'react';
import { IconContainer } from '../../Buttons/IconContainer';
import { UnderlinedButton } from '../../Buttons/UnderlinedButton';
import styles from './FormContainer.module.css';
import { useForm, FormProvider } from "react-hook-form";
import { LoadingIconContainer } from '../../Buttons/LoadingIconContainer';

export interface FormContainerProps {
    className?: string;
    children: any;
    form: any;
    footer?: (props: any) => ReactElement;
    [rest: string]: unknown;
    formContentClassName?: string;
    formFooterClassName?: string;
}
export const FormContainer = ({ className = '', children, form, footer,
    formContentClassName = styles.form_content,
    formFooterClassName = styles.form_footer, ...rest }: FormContainerProps) => {
    const {
        formState: { isSubmitting }
    } = form;

    return (
        <FormProvider {...form} >
            <form className={className} {...rest}>
                <div className={formContentClassName}>
                    {children}
                </div>
                {footer &&
                    <div className={formFooterClassName}>
                        {footer({})}
                    </div>
                }

            </form>
        </FormProvider>

    );
}